<template>
  <div class="container-fluid px-4">
    <div class="row">
      <div class="col-12 pl-0 text-center col-lg-12">
        <h3>Masterlist Section</h3>
      </div>
      <div class="pl-0 col-lg-3">
<!--        <quick-links />-->
      </div>
    </div>
  </div>
</template>

<script>
// import Quicklinks from '../components/layouts/Quicklinks'

export default {
  name: 'Masterlist',
  components: {
    // 'quick-links': Quicklinks
  }
}
</script>

<style scoped>

</style>
